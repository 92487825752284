define('bgr-ember-modal/mixins/bgr-modal-controller-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    /**
     * injections
     */

    bgrModal: Ember.inject.service(),

    /**
     * props
     */

    modalQueryParams: null,

    /**
     * state
     */

    _cachePerQueryParam: null,

    /**
     * hooks
     */

    init() {
      this._super(...arguments);

      let modalQueryParams = this.get('modalQueryParams');

      if (modalQueryParams !== Object(modalQueryParams)) {
        return;
      }

      let queryParams = this.get('queryParams');
      let cachePerQueryParam = {};

      Object.keys(modalQueryParams).forEach(queryParam => {
        (false && !(queryParams.includes(queryParam)) && Ember.assert(`'${queryParam}' should also be included in 'queryParams'.`, queryParams.includes(queryParam)));


        cachePerQueryParam[queryParam] = {
          defaultValue: this.get(queryParam),
          modal: null
        };

        this.addObserver(queryParam, this, this._handleQueryParamChange);
      });

      this.set('_cachePerQueryParam', cachePerQueryParam);
    },

    /**
     * methods
     */

    _handleQueryParamChange(controller, queryParam) {
      let queryParamValue = this.get(queryParam);
      let queryParamCache = this.get('_cachePerQueryParam')[queryParam];
      let queryParamModalName = this.get('modalQueryParams')[queryParam];
      let queryParamDefaultValue = queryParamCache.defaultValue;
      let queryParamModal = queryParamCache.modal;

      if (queryParamValue !== queryParamDefaultValue) {
        queryParamModal = this.get('bgrModal').create(queryParamModalName, {}, {
          queryParam: queryParamValue
        });

        queryParamCache.modal = queryParamModal;

        queryParamModal.one('hide', () => {
          queryParamCache.modal = null;
          this.set(queryParam, queryParamDefaultValue);
        });

        queryParamModal.show();
      } else if (queryParamModal) {
        queryParamModal.hide();
      }
    }
  });
});