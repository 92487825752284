define('bgr-ember-modal/helpers/bgr-modal-action', ['exports', '@ember/string'], function (exports, _string) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const VALID_ACTIONS = ['add', 'alert', 'confirm', 'hide', 'hide-all', 'hide-upper', 'show'];

  const VALID_ACTIONS_STRING = VALID_ACTIONS.join(', ');

  exports.default = Ember.Helper.extend({
    bgrModal: Ember.inject.service(),

    compute(params) {
      let action = params[0];

      (false && !(VALID_ACTIONS.includes(action)) && Ember.assert(`The provided action should be one of the following: ${VALID_ACTIONS_STRING}.`, VALID_ACTIONS.includes(action)));


      return () => {
        return this.get('bgrModal')[(0, _string.camelize)(action)](...params.slice(1));
      };
    }
  });
});