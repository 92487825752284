define("bgr-ember-flash/services/bgr-flash/public-api", ["exports", "bgr-ember-flash/services/bgr-flash/flash", "bgr-ember-flash/config"], function (_exports, _flash, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    flashConfig,
    flashLevels
  } = _config.default;
  const flashLevelsArray = Object.keys(flashLevels);
  const flashLevelsString = flashLevelsArray.join(', ');
  const mixinBlueprint = {
    /**
     * state
     */
    flashStack: Ember.A([]),

    /**
     * methods
     */
    create(level, message, options) {
      let flashLevelConfig = flashLevels[level];
      (false && !(flashLevelConfig) && Ember.assert(`The 'level' parameter should have one of the following values: ${flashLevelsString}.`, flashLevelConfig));
      (false && !(message) && Ember.assert(`The 'message' parameter is required.`, message));
      return _flash.default.create({
        level,
        message,
        options: Object.assign({}, flashConfig, flashLevelConfig, options),
        service: this
      });
    },

    hideAll() {
      this.get('flashStack').clear();
    }

  };
  flashLevelsArray.forEach(flashLevel => {
    mixinBlueprint[flashLevel] = function (message, options) {
      return this.create(flashLevel, message, options).show();
    };
  });

  var _default = Ember.Mixin.create(mixinBlueprint);

  _exports.default = _default;
});