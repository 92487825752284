define("ember-cli-bugsnag/utils/bugsnag", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getMetadata = getMetadata;
  exports.getUser = getUser;
  function getMetadata() /* error, container */{
    return {};
  }

  function getUser() /* container */{
    return {};
  }
});